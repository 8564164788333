import React from "react";
import {
  COLORS,
  GRID_SIZE,
  NOTE_COLORS,
  WAVE_TYPES,
  ICONS,
} from "../constants";
import GridBackground from "./GridBackground";
import GridLines from "./GridLines";
import Cell from "./Cell";
import Playhead from "./Playhead";
import ActiveCell from "./ActiveCell";

const Grid = ({ grid, currentColumn, onClick, onContextMenu, isMobile }) => {
  const size = isMobile ? Math.min(window.innerWidth - 32, 400) : 400;
  const cellSize = size / GRID_SIZE;

  return (
    <svg
      width={size}
      height={size}
      style={{
        border: `2px solid ${COLORS.red}`,
        borderRadius: "8px",
      }}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      <GridBackground width={size} height={size} color={COLORS.background} />
      <GridLines
        cellSize={cellSize}
        gridSize={GRID_SIZE}
        width={size}
        height={size}
        color={COLORS.red}
      />
      {grid.map((row, rowIndex) =>
        row.map((cell, colIndex) => (
          <Cell
            key={`${rowIndex}-${colIndex}`}
            cell={cell}
            rowIndex={rowIndex}
            colIndex={colIndex}
            cellSize={cellSize}
            icons={ICONS}
            waveTypes={WAVE_TYPES}
            noteColors={NOTE_COLORS}
            colors={COLORS}
          />
        )),
      )}
      <Playhead
        currentColumn={currentColumn}
        cellSize={cellSize}
        height={size}
        color={COLORS.blue}
      />
      {grid.map(
        (row, rowIndex) =>
          row[currentColumn] && (
            <ActiveCell
              key={`active-${rowIndex}`}
              rowIndex={rowIndex}
              colIndex={currentColumn}
              cellSize={cellSize}
              color={COLORS.blue}
            />
          ),
      )}
    </svg>
  );
};

export default Grid;
