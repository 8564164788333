import React, { useState } from "react";
import { COLORS } from "../constants";

const Cell = ({
  cell,
  rowIndex,
  colIndex,
  cellSize,
  icons,
  waveTypes,
  noteColors,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  if (!cell) return null;

  const waveTypeIndex = noteColors.indexOf(cell);
  const waveType = waveTypes[waveTypeIndex];

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <g onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <rect
        x={colIndex * cellSize}
        y={rowIndex * cellSize}
        width={cellSize}
        height={cellSize}
        fill={isHovered ? COLORS.black_highlight : COLORS.black}
        stroke={COLORS.red}
        strokeWidth="1"
        style={{ transition: "fill 0.15s ease" }}
      />
      <path
        d={icons[waveType]}
        stroke={cell}
        strokeWidth={2}
        fill="none"
        transform={`translate(${colIndex * cellSize + cellSize * 0.2}, ${rowIndex * cellSize + cellSize * 0.3}) scale(${cellSize * 0.03})`}
      />
    </g>
  );
};

export default Cell;
