// hooks/useEffects.js
import { useState, useCallback } from "react";
import { setFilterFrequency } from "../soundGenerator";

export const useEffects = () => {
  const [activeEffects, setActiveEffects] = useState({
    lowpass: false,
    highpass: false,
    phaser: false,
    reverb: false,
    delay: false,
    bitcrusher: false,
    flanger: false,
    reverse: false,
  });

  const handleEffectToggle = useCallback((effectName) => {
    setActiveEffects((prev) => {
      const newActiveEffects = { ...prev, [effectName]: !prev[effectName] };

      if (effectName === "lowpass") {
        setFilterFrequency(newActiveEffects.lowpass ? 1000 : 20000);
      }
      // Add logic for other effects here

      return newActiveEffects;
    });
  }, []);

  return { activeEffects, handleEffectToggle };
};
