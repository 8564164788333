import React, { useState, useEffect } from "react";
import { COLORS } from "./constants";
import Grid from "./Grid/Grid";
import Controls from "./Controls/Controls";
import MobileControls from "./Controls/MobileControls";
import useMusicalSudoku from "./hooks/useMusicalSudoku";

const MusicalSudoku = () => {
  const [isMobile, setIsMobile] = useState(false);
  const {
    grid,
    isPlaying,
    currentColumn,
    tempo,
    activeEffects,
    handleGridClick,
    handleContextMenu,
    togglePlay,
    handleClear,
    setTempo,
    handleEffectToggle,
  } = useMusicalSudoku();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center p-4"
      style={{
        background: "linear-gradient(135deg, #0B0000, #400002)",
        color: COLORS.base0,
      }}
    >
      <h1
        className={`${isMobile ? "text-2xl" : "text-3xl"} mb-2`}
        style={{
          color: COLORS.base3,
          fontFamily: "'Space Grotesk', sans-serif",
          fontWeight: 200,
        }}
      >
        m∞uzoku
      </h1>
      <div
        className={`p-2 ${isMobile ? "w-auto" : "w-auto"} rounded-lg shadow-lg`}
      >
        <Grid
          grid={grid}
          currentColumn={currentColumn}
          onClick={handleGridClick}
          onContextMenu={handleContextMenu}
          isMobile={isMobile}
        />
      </div>
      {isMobile ? (
        <MobileControls
          isPlaying={isPlaying}
          togglePlay={togglePlay}
          handleClear={handleClear}
          tempo={tempo}
          setTempo={setTempo}
          activeEffects={activeEffects}
          handleEffectToggle={handleEffectToggle}
        />
      ) : (
        <Controls
          isPlaying={isPlaying}
          togglePlay={togglePlay}
          handleClear={handleClear}
          tempo={tempo}
          setTempo={setTempo}
          activeEffects={activeEffects}
          handleEffectToggle={handleEffectToggle}
        />
      )}
    </div>
  );
};

export default MusicalSudoku;
