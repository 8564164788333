import React from "react";

const ActiveCell = ({ rowIndex, colIndex, cellSize, color }) => {
  const centerX = (colIndex + 0.5) * cellSize;
  const centerY = (rowIndex + 0.5) * cellSize;
  const radius = cellSize * 0.25;

  return (
    <circle cx={centerX} cy={centerY} r={radius} fill={color} opacity={0.3} />
  );
};

export default ActiveCell;
