import React from "react";
import { COLORS } from "./constants";

const TempoControl = ({ tempo, setTempo }) => {
  const handleTempoChange = (e) => {
    const newTempo = parseInt(e.target.value, 10);
    setTempo(newTempo);
  };

  return (
    <div className="mt-8 flex items-center">
      <label
        htmlFor="tempo"
        className="mr-4 text-lg"
        style={{ color: COLORS.textSecondary }}
      >
        Tempo: <span style={{ color: COLORS.blue }}>{tempo} BPM</span>
      </label>
      <input
        type="range"
        id="tempo"
        min="60"
        max="240"
        value={tempo}
        onChange={handleTempoChange}
        className="w-48"
        style={{
          accentColor: COLORS.base02,
          background: COLORS.base0,
        }}
      />
    </div>
  );
};

export default TempoControl;
