import { useState, useCallback, useEffect } from "react";
import { useGrid } from "./useGrid";
import { useSequencer } from "./useSequencer";
import { useAudioContext } from "./useAudioContext";
import { useEffects } from "./useEffects";
import { useKeyboardControls } from "./useKeyboardControls";
import { playSound } from "../soundGenerator/soundPlayer";
import { GRID_SIZE, NOTE_COLORS, WAVE_TYPES } from "../constants";

const useMusicalSudoku = () => {
  const [tempo, setTempo] = useState(120);
  const { audioContext, getAudioContext } = useAudioContext();
  const { isPlaying, currentColumn, togglePlay } = useSequencer(tempo);
  const { grid, handleGridClick, handleClear, handleContextMenu } =
    useGrid(getAudioContext);
  const { activeEffects, handleEffectToggle } = useEffects();
  useKeyboardControls(togglePlay);

  const handleSound = useCallback(
    (row, col) => {
      const context = getAudioContext();
      if (context) {
        const waveTypeIndex = NOTE_COLORS.indexOf(grid[row][col]);
        const waveType = WAVE_TYPES[waveTypeIndex];
        playSound(context, row, waveType);
      }
    },
    [getAudioContext, grid],
  );

  useEffect(() => {
    if (isPlaying && audioContext) {
      for (let row = 0; row < GRID_SIZE; row++) {
        if (grid[row][currentColumn]) {
          handleSound(row, currentColumn);
        }
      }
    }
  }, [currentColumn, isPlaying, grid, audioContext, handleSound]);

  return {
    grid,
    isPlaying,
    currentColumn,
    tempo,
    activeEffects,
    handleGridClick,
    handleContextMenu,
    togglePlay,
    handleClear,
    setTempo,
    handleEffectToggle,
    handleSound,
  };
};

export default useMusicalSudoku;
