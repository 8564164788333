// Controls/Controls.js
import React from "react";
import { COLORS } from "../constants";
import TempoControl from "../TempoControl";

const Controls = ({ isPlaying, togglePlay, handleClear, tempo, setTempo }) => (
  <div className="mt-8 flex flex-col items-center">
    <div className="flex space-x-4">
      <button
        className="px-6 py-2 rounded-full text-lg font-semibold transition-colors duration-300"
        style={{
          backgroundColor: isPlaying ? COLORS.magenta : COLORS.blue,
          color: COLORS.base3,
        }}
        onClick={togglePlay}
      >
        {isPlaying ? "Pause" : "Play"}
      </button>
      <button
        className="px-6 py-2 rounded-full text-lg font-semibold transition-colors duration-300"
        style={{
          backgroundColor: COLORS.red,
          color: COLORS.base3,
        }}
        onClick={handleClear}
      >
        Clear
      </button>
    </div>
    <p className="mt-2 text-sm" style={{ color: COLORS.base1 }}>
      Press spacebar to play/pause
    </p>
    <TempoControl tempo={tempo} setTempo={setTempo} />
  </div>
);

export default Controls;
