import React from "react";

const GridLines = ({ cellSize, gridSize, width, height, color }) => {
  const lines = [];
  for (let i = 1; i < gridSize; i++) {
    lines.push(
      <line
        key={`v${i}`}
        x1={i * cellSize}
        y1={0}
        x2={i * cellSize}
        y2={height}
        stroke={color}
      />,
      <line
        key={`h${i}`}
        x1={0}
        y1={i * cellSize}
        x2={width}
        y2={i * cellSize}
        stroke={color}
      />,
    );
  }
  return <>{lines}</>;
};

export default GridLines;
