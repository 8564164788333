import React from "react";
import { COLORS } from "../constants";
import TempoControl from "../TempoControl";

const MobileControls = ({
  isPlaying,
  togglePlay,
  handleClear,
  tempo,
  setTempo,
  activeEffects,
  handleEffectToggle,
}) => {
  return (
    <div className="w-full mt-4 flex flex-col items-center">
      <div className="flex space-x-4 mb-4">
        <button
          className="px-4 py-2 rounded-full text-base font-semibold transition-colors duration-300"
          style={{
            backgroundColor: isPlaying ? COLORS.magenta : COLORS.blue,
            color: COLORS.base3,
          }}
          onClick={togglePlay}
        >
          {isPlaying ? "Pause" : "Play"}
        </button>
        <button
          className="px-4 py-2 rounded-full text-base font-semibold transition-colors duration-300"
          style={{
            backgroundColor: COLORS.red,
            color: COLORS.base3,
          }}
          onClick={handleClear}
        >
          Clear
        </button>
      </div>
      <TempoControl tempo={tempo} setTempo={setTempo} isMobile={true} />
    </div>
  );
};

export default MobileControls;
