import { oscillatorTypes } from "./oscillatorTypes";

export const playSound = (context, row, type) => {
  const baseFrequency = 65.4;
  const frequency = baseFrequency * Math.pow(2, (row * 5) / 12);
  const gainNode = context.createGain();
  const source = oscillatorTypes[type](context, frequency);

  let outputNode;
  let duration = 0.2; // Default duration

  switch (type) {
    case "pulse":
      outputNode = source.output;
      source.oscillators.forEach((osc) => {
        osc.start();
        osc.stop(context.currentTime + duration);
      });
      break;

    case "kickDrum":
      outputNode = source.output;
      source.oscillator.start();
      source.oscillator.stop(context.currentTime + 0.3); // Longer duration for kick
      duration = 0.3;
      break;

    case "noise":
      outputNode = source;
      source.start();
      source.stop(context.currentTime + duration);
      break;

    case "rhodes":
      outputNode = source.output;
      source.oscillator.start();
      duration = 1.0;
      source.oscillator.stop(context.currentTime + duration);
      break;
    case "fmBrass":
      outputNode = source.output;
      source.oscillator.start();
      duration = 0.5;
      source.oscillator.stop(context.currentTime + duration);
      break;

    default:
      outputNode = source;
      source.start();
      source.stop(context.currentTime + duration);
  }

  outputNode.connect(gainNode);
  gainNode.connect(context.destination);

  // Adjust gain envelope based on the sound type
  if (type === "kickDrum" || type === "rhodes") {
    // Kick drum has its own gain envelope, so we keep the main gain at 1
    gainNode.gain.setValueAtTime(1, context.currentTime);
  } else {
    // For other sounds, use the original gain envelope
    gainNode.gain.setValueAtTime(0.1, context.currentTime);
    gainNode.gain.exponentialRampToValueAtTime(
      0.001,
      context.currentTime + duration,
    );
  }
};
