// soundGenerator.js
const AudioContext = window.AudioContext || window.webkitAudioContext;

let globalFilterNode = null;

const createAudioContext = () => {
  const context = new AudioContext();
  globalFilterNode = context.createBiquadFilter();
  globalFilterNode.type = "lowpass";
  globalFilterNode.frequency.setValueAtTime(20000, context.currentTime); // Start fully open
  globalFilterNode.Q.setValueAtTime(1, context.currentTime);
  globalFilterNode.connect(context.destination);
  return context;
};

const oscillatorTypes = {
  sine: (context, frequency) => {
    const oscillator = context.createOscillator();
    oscillator.type = "sine";
    oscillator.frequency.setValueAtTime(frequency, context.currentTime);
    return oscillator;
  },
  square: (context, frequency) => {
    const oscillator = context.createOscillator();
    oscillator.type = "square";
    oscillator.frequency.setValueAtTime(frequency, context.currentTime);
    return oscillator;
  },
  sawtooth: (context, frequency) => {
    const oscillator = context.createOscillator();
    oscillator.type = "sawtooth";
    oscillator.frequency.setValueAtTime(frequency, context.currentTime);
    return oscillator;
  },
  triangle: (context, frequency) => {
    const oscillator = context.createOscillator();
    oscillator.type = "triangle";
    oscillator.frequency.setValueAtTime(frequency, context.currentTime);
    return oscillator;
  },
  pulse: (context, frequency) => {
    const osc1 = context.createOscillator();
    const osc2 = context.createOscillator();
    const pulseWidth = 0.3;
    osc1.type = "sawtooth";
    osc2.type = "sawtooth";
    osc1.frequency.setValueAtTime(frequency, context.currentTime);
    osc2.frequency.setValueAtTime(frequency, context.currentTime);
    osc2.detune.setValueAtTime(pulseWidth * 100, context.currentTime);
    const inverter = context.createGain();
    inverter.gain.setValueAtTime(-1, context.currentTime);
    const merger = context.createGain();
    osc1.connect(merger);
    osc2.connect(inverter);
    inverter.connect(merger);
    return {
      oscillators: [osc1, osc2],
      output: merger,
    };
  },
  noise: (context) => {
    const bufferSize = context.sampleRate * 0.2;
    const noiseBuffer = context.createBuffer(1, bufferSize, context.sampleRate);
    const output = noiseBuffer.getChannelData(0);
    for (let i = 0; i < bufferSize; i++) {
      output[i] = Math.random() * 2 - 1;
    }
    const whiteNoise = context.createBufferSource();
    whiteNoise.buffer = noiseBuffer;
    return whiteNoise;
  },

  kickDrum: (context, frequency) => {
    const osc = context.createOscillator();
    const gain = context.createGain();

    osc.frequency.setValueAtTime(frequency, context.currentTime);
    osc.frequency.exponentialRampToValueAtTime(
      frequency / 4,
      context.currentTime + 0.1,
    );

    gain.gain.setValueAtTime(1, context.currentTime);
    gain.gain.exponentialRampToValueAtTime(0.001, context.currentTime + 0.3);

    osc.connect(gain);
    return { oscillator: osc, output: gain };
  },
};

const playSound = (context, row, type) => {
  const baseFrequency = 65.4;
  const frequency = baseFrequency * Math.pow(2, (row * 5) / 12);
  const gainNode = context.createGain();
  const source = oscillatorTypes[type](context, frequency);

  let outputNode;
  let duration = 0.2; // Default duration

  switch (type) {
    case "pulse":
      outputNode = source.output;
      source.oscillators.forEach((osc) => {
        osc.start();
        osc.stop(context.currentTime + duration);
      });
      break;

    case "kickDrum":
      outputNode = source.output;
      source.oscillator.start();
      source.oscillator.stop(context.currentTime + 0.3); // Longer duration for kick
      duration = 0.3;
      break;

    case "noise":
      outputNode = source;
      source.start();
      source.stop(context.currentTime + duration);
      break;

    default:
      outputNode = source;
      source.start();
      source.stop(context.currentTime + duration);
  }

  outputNode.connect(gainNode);
  gainNode.connect(globalFilterNode);

  // Adjust gain envelope based on the sound type
  if (type === "kickDrum") {
    // Kick drum has its own gain envelope, so we keep the main gain at 1
    gainNode.gain.setValueAtTime(1, context.currentTime);
  } else {
    // For other sounds, use the original gain envelope
    gainNode.gain.setValueAtTime(0.1, context.currentTime);
    gainNode.gain.exponentialRampToValueAtTime(
      0.001,
      context.currentTime + duration,
    );
  }
};

const setFilterFrequency = (frequency) => {
  if (globalFilterNode) {
    globalFilterNode.frequency.setValueAtTime(
      frequency,
      globalFilterNode.context.currentTime,
    );
  }
};

export { createAudioContext, playSound, setFilterFrequency };
