import { useState, useCallback, useRef, useEffect } from "react";
import { GRID_SIZE, NOTE_COLORS, WAVE_TYPES } from "../constants";
import { playSound } from "../soundGenerator/soundPlayer";

export const useGrid = (getAudioContext) => {
  const [grid, setGrid] = useState(
    Array(GRID_SIZE)
      .fill()
      .map(() => Array(GRID_SIZE).fill(null)),
  );
  const [isMobile, setIsMobile] = useState(false);
  const lastTapTime = useRef(null);
  const lastTapPosition = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ),
      );
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const handleCellInteraction = useCallback(
    (row, col, isRightClick = false) => {
      setGrid((prevGrid) => {
        const newGrid = prevGrid.map((row) => [...row]);
        if (isRightClick) {
          // Remove the note on right-click (desktop)
          newGrid[row][col] = null;
        } else {
          const currentColorIndex = NOTE_COLORS.indexOf(newGrid[row][col]);
          const nextColorIndex = (currentColorIndex + 1) % NOTE_COLORS.length;
          newGrid[row][col] = NOTE_COLORS[nextColorIndex];
          const audioContext = getAudioContext();
          if (audioContext) {
            playSound(audioContext, row, WAVE_TYPES[nextColorIndex]);
          }
        }
        return newGrid;
      });
    },
    [getAudioContext],
  );

  const handleGridClick = useCallback(
    (event) => {
      event.preventDefault(); // Prevent default behavior for both left and right clicks
      const svgElement = event.currentTarget;
      const rect = svgElement.getBoundingClientRect();
      const scaleX = svgElement.width.baseVal.value / rect.width;
      const scaleY = svgElement.height.baseVal.value / rect.height;
      const x = (event.clientX - rect.left) * scaleX;
      const y = (event.clientY - rect.top) * scaleY;
      const cellSize = svgElement.width.baseVal.value / GRID_SIZE;
      const col = Math.floor(x / cellSize);
      const row = Math.floor(y / cellSize);

      if (row >= 0 && row < GRID_SIZE && col >= 0 && col < GRID_SIZE) {
        if (isMobile) {
          const currentTime = new Date().getTime();
          const isDoubleTap =
            lastTapTime.current &&
            currentTime - lastTapTime.current < 300 &&
            lastTapPosition.current &&
            lastTapPosition.current.row === row &&
            lastTapPosition.current.col === col;

          if (isDoubleTap) {
            handleCellInteraction(row, col, true); // Treat double-tap as a right-click (remove note)
          } else {
            handleCellInteraction(row, col); // Single tap, cycle through colors
          }

          lastTapTime.current = currentTime;
          lastTapPosition.current = { row, col };
        } else {
          const isRightClick =
            event.button === 2 || // Right mouse button
            (event.ctrlKey && event.button === 0) || // Ctrl + left click
            (event.button === 0 && event.buttons === 2); // Two-finger click on most touchpads
          handleCellInteraction(row, col, isRightClick);
        }
      }
    },
    [handleCellInteraction, isMobile],
  );

  const handleContextMenu = useCallback((event) => {
    event.preventDefault(); // Prevent the context menu from appearing
  }, []);

  const handleClear = useCallback(() => {
    setGrid(
      Array(GRID_SIZE)
        .fill()
        .map(() => Array(GRID_SIZE).fill(null)),
    );
  }, []);

  return { grid, handleGridClick, handleContextMenu, handleClear };
};
