const AudioContext = window.AudioContext || window.webkitAudioContext;

let globalFilterNode = null;

export const createAudioContext = () => {
  const context = new AudioContext();
  globalFilterNode = context.createBiquadFilter();
  globalFilterNode.type = "lowpass";
  globalFilterNode.frequency.setValueAtTime(20000, context.currentTime);
  globalFilterNode.Q.setValueAtTime(1, context.currentTime);
  globalFilterNode.connect(context.destination);
  return context;
};

export const setFilterFrequency = (frequency) => {
  if (globalFilterNode) {
    globalFilterNode.frequency.setValueAtTime(
      frequency,
      globalFilterNode.context.currentTime,
    );
  }
};
