// hooks/useSequencer.js
import { useState, useEffect, useRef, useCallback } from "react";
import { GRID_SIZE } from "../constants";

export const useSequencer = (tempo, grid, audioContext) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentColumn, setCurrentColumn] = useState(0);
  const sequencerIntervalRef = useRef();

  useEffect(() => {
    if (isPlaying) {
      sequencerIntervalRef.current = setInterval(
        () => {
          setCurrentColumn((prev) => (prev + 1) % GRID_SIZE);
        },
        60000 / tempo / 4,
      );
    } else {
      clearInterval(sequencerIntervalRef.current);
    }
    return () => clearInterval(sequencerIntervalRef.current);
  }, [isPlaying, tempo]);

  const togglePlay = useCallback(() => {
    setIsPlaying((prevIsPlaying) => {
      if (!prevIsPlaying) {
        setCurrentColumn(0);
      }
      return !prevIsPlaying;
    });
  }, []);

  return { isPlaying, currentColumn, togglePlay, setIsPlaying };
};
