import { useState, useEffect, useRef } from "react";
import { createAudioContext } from "../soundGenerator/audioContext";

export const useAudioContext = () => {
  const [audioContext, setAudioContext] = useState(null);
  const audioContextRef = useRef(null);

  useEffect(() => {
    const initAudioContext = () => {
      if (!audioContextRef.current) {
        audioContextRef.current = createAudioContext();
        setAudioContext(audioContextRef.current);
      }
    };

    initAudioContext();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
        setAudioContext(null);
      }
    };
  }, []);

  const getAudioContext = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = createAudioContext();
      setAudioContext(audioContextRef.current);
    }
    return audioContextRef.current;
  };

  return { audioContext, getAudioContext };
};
