import React from "react";
import MusicalSudoku from "./MusicalSudoku";

function App() {
  return (
    <div className="App">
      <MusicalSudoku />
    </div>
  );
}

export default App;
