import React from "react";

const Playhead = ({ currentColumn, cellSize, height, color }) => {
  return (
    <rect
      x={currentColumn * cellSize}
      y={0}
      width={cellSize}
      height={height}
      fill={color}
      opacity={0.6}
      style={{
        mixBlendMode: "color-dodge", // or "multiply"
      }}
    />
  );
};

export default Playhead;
