const createFMOscillator = (context, frequency, modulationIndex) => {
  console.log(
    "createFMOscillator: Starting with frequency",
    frequency,
    "and modulationIndex",
    modulationIndex,
  );

  const carrier = context.createOscillator();
  const modulator = context.createOscillator();
  const modulationGain = context.createGain();

  carrier.frequency.value = frequency;
  modulator.frequency.value = frequency;
  modulationGain.gain.value = frequency * modulationIndex;

  modulator.connect(modulationGain);
  modulationGain.connect(carrier.frequency);

  console.log(
    "createFMOscillator: Created carrier, modulator, and modulationGain",
  );
  console.log("createFMOscillator: modulationGain is", modulationGain);

  return { carrier, modulator, modulationGain };
};

export const oscillatorTypes = {
  sine: (context, frequency) => {
    const oscillator = context.createOscillator();
    oscillator.type = "sine";
    oscillator.frequency.setValueAtTime(frequency, context.currentTime);
    return oscillator;
  },
  square: (context, frequency) => {
    const oscillator = context.createOscillator();
    oscillator.type = "square";
    oscillator.frequency.setValueAtTime(frequency, context.currentTime);
    return oscillator;
  },
  sawtooth: (context, frequency) => {
    const oscillator = context.createOscillator();
    oscillator.type = "sawtooth";
    oscillator.frequency.setValueAtTime(frequency, context.currentTime);
    return oscillator;
  },
  triangle: (context, frequency) => {
    const oscillator = context.createOscillator();
    oscillator.type = "triangle";
    oscillator.frequency.setValueAtTime(frequency, context.currentTime);
    return oscillator;
  },
  pulse: (context, frequency) => {
    const osc1 = context.createOscillator();
    const osc2 = context.createOscillator();
    const pulseWidth = 0.3;
    osc1.type = "sawtooth";
    osc2.type = "sawtooth";
    osc1.frequency.setValueAtTime(frequency, context.currentTime);
    osc2.frequency.setValueAtTime(frequency, context.currentTime);
    osc2.detune.setValueAtTime(pulseWidth * 100, context.currentTime);
    const inverter = context.createGain();
    inverter.gain.setValueAtTime(-1, context.currentTime);
    const merger = context.createGain();
    osc1.connect(merger);
    osc2.connect(inverter);
    inverter.connect(merger);
    return {
      oscillators: [osc1, osc2],
      output: merger,
    };
  },
  noise: (context) => {
    const bufferSize = context.sampleRate * 0.2;
    const noiseBuffer = context.createBuffer(1, bufferSize, context.sampleRate);
    const output = noiseBuffer.getChannelData(0);
    for (let i = 0; i < bufferSize; i++) {
      output[i] = Math.random() * 2 - 1;
    }
    const whiteNoise = context.createBufferSource();
    whiteNoise.buffer = noiseBuffer;
    return whiteNoise;
  },
  kickDrum: (context, frequency) => {
    const osc = context.createOscillator();
    const gain = context.createGain();

    osc.frequency.setValueAtTime(frequency, context.currentTime);
    osc.frequency.exponentialRampToValueAtTime(
      frequency / 4,
      context.currentTime + 0.1,
    );

    gain.gain.setValueAtTime(1, context.currentTime);
    gain.gain.exponentialRampToValueAtTime(0.001, context.currentTime + 0.3);

    osc.connect(gain);
    return { oscillator: osc, output: gain };
  },
  rhodes: (context, frequency) => {
    const baseFreq = frequency;
    const op1 = createFMOscillator(context, baseFreq, 0.1);
    const op2 = createFMOscillator(context, baseFreq * 2, 0.5);
    const op3 = createFMOscillator(context, baseFreq * 4, 0.2);
    const op4 = createFMOscillator(context, baseFreq * 6, 0.1);

    const gain1 = context.createGain();
    const gain2 = context.createGain();
    const gain3 = context.createGain();
    const gain4 = context.createGain();
    const mainGain = context.createGain();

    gain1.gain.setValueAtTime(0.2, context.currentTime);
    gain2.gain.setValueAtTime(0.09, context.currentTime);
    gain3.gain.setValueAtTime(0.1, context.currentTime);
    gain4.gain.setValueAtTime(0.09, context.currentTime);

    mainGain.gain.setValueAtTime(0.8, context.currentTime);
    mainGain.gain.exponentialRampToValueAtTime(0.01, context.currentTime + 0.5);

    op1.carrier.connect(gain1);
    op2.carrier.connect(gain2);
    op3.carrier.connect(gain3);
    op4.carrier.connect(gain4);

    gain1.connect(mainGain);
    gain2.connect(mainGain);
    gain3.connect(mainGain);
    gain4.connect(mainGain);

    const start = () => {
      op1.carrier.start();
      op1.modulator.start();
      op2.carrier.start();
      op2.modulator.start();
      op3.carrier.start();
      op3.modulator.start();
      op4.carrier.start();
      op4.modulator.start();
    };

    const stop = (time) => {
      op1.carrier.stop(time);
      op1.modulator.stop(time);
      op2.carrier.stop(time);
      op2.modulator.stop(time);
      op3.carrier.stop(time);
      op3.modulator.stop(time);
      op4.carrier.stop(time);
      op4.modulator.stop(time);
    };

    return { oscillator: { start, stop }, output: mainGain };
  },
  fmBrass: (context, frequency) => {
    console.log("fmBrass: Starting with frequency", frequency);
    const baseFreq = frequency;

    console.log("fmBrass: Creating operators");
    // Create four FM operators
    const op1 = createFMOscillator(context, baseFreq, 0.2); // Carrier
    const op2 = createFMOscillator(context, baseFreq * 8, 0.1); // Modulator for op1
    const op3 = createFMOscillator(context, baseFreq * 2, 0.2); // Modulator for op2
    const op4 = createFMOscillator(context, baseFreq * 12, 0.1); // Additional modulator for op1

    console.log("fmBrass: Operators created", { op1, op2, op3, op4 });

    console.log("fmBrass: Creating gain nodes");
    // Create gain nodes for mixing
    const gain1 = context.createGain();
    const gain2 = context.createGain();
    const gain3 = context.createGain();
    const gain4 = context.createGain();
    const mainGain = context.createGain();

    console.log("fmBrass: Setting initial gain values");
    // Set initial gain values
    gain1.gain.setValueAtTime(1, context.currentTime);
    gain2.gain.setValueAtTime(0.4, context.currentTime);
    gain3.gain.setValueAtTime(0.5, context.currentTime);
    gain4.gain.setValueAtTime(0.7, context.currentTime);

    console.log("fmBrass: Connecting operators");
    // Connect operators
    try {
      op2.carrier.connect(op1.modulationGain);
      op3.carrier.connect(op2.modulationGain);
      op4.carrier.connect(op1.modulationGain);

      op1.carrier.connect(gain1);
      op2.carrier.connect(gain2);
      op3.carrier.connect(gain3);
      op4.carrier.connect(gain4);

      gain1.connect(mainGain);
      gain2.connect(mainGain);
      gain3.connect(mainGain);
      gain4.connect(mainGain);
    } catch (error) {
      console.error("fmBrass: Error connecting operators", error);
    }

    console.log("fmBrass: Creating vibrato");
    // Add vibrato
    const vibratoOsc = context.createOscillator();
    const vibratoGain = context.createGain();
    vibratoOsc.frequency.setValueAtTime(5, context.currentTime);
    vibratoGain.gain.setValueAtTime(2, context.currentTime);
    vibratoOsc.connect(vibratoGain);
    vibratoGain.connect(op1.carrier.frequency);

    console.log("fmBrass: Setting up start function");
    const start = () => {
      console.log("fmBrass start: Beginning");
      const now = context.currentTime;
      op1.carrier.start(now);
      op1.modulator.start(now);
      op2.carrier.start(now);
      op2.modulator.start(now);
      op3.carrier.start(now);
      op3.modulator.start(now);
      op4.carrier.start(now);
      op4.modulator.start(now);
      vibratoOsc.start(now);

      console.log("fmBrass start: Setting dynamic modulation");
      // Dynamic modulation index
      op1.modulationGain.gain.exponentialRampToValueAtTime(
        baseFreq * 1,
        now + 0.1,
      );
      op2.modulationGain.gain.exponentialRampToValueAtTime(
        baseFreq * 2.5,
        now + 0.1,
      );
      op3.modulationGain.gain.exponentialRampToValueAtTime(baseFreq, now + 0.1);
      op4.modulationGain.gain.exponentialRampToValueAtTime(
        baseFreq / 2,
        now + 0.1,
      );

      console.log("fmBrass start: Setting ADSR envelope");
      // ADSR envelope
      mainGain.gain.setValueAtTime(0, now);
      mainGain.gain.linearRampToValueAtTime(0.8, now + 0.02); // Quick attack
      mainGain.gain.linearRampToValueAtTime(0.9, now + 0.08); // Short decay
      mainGain.gain.linearRampToValueAtTime(0.2, now + 0.1); // Second peak
      mainGain.gain.linearRampToValueAtTime(0.1, now + 0.2); // Decay to sustain level
      mainGain.gain.setTargetAtTime(0.05, now + 0.2, 0.1); // Sustain
    };

    console.log("fmBrass: Setting up stop function");
    const stop = (time) => {
      console.log("fmBrass stop: Beginning");
      const stopTime = time || context.currentTime;
      mainGain.gain.setValueAtTime(mainGain.gain.value, stopTime);
      mainGain.gain.linearRampToValueAtTime(0.06, stopTime + 0.2); // Release

      const releaseTime = stopTime + 0.6;
      op1.carrier.stop(releaseTime);
      op1.modulator.stop(releaseTime);
      op2.carrier.stop(releaseTime);
      op2.modulator.stop(releaseTime);
      op3.carrier.stop(releaseTime);
      op3.modulator.stop(releaseTime);
      op4.carrier.stop(releaseTime);
      op4.modulator.stop(releaseTime);
      vibratoOsc.stop(releaseTime);
      console.log("fmBrass stop: Completed");
    };

    console.log("fmBrass: Returning oscillator object");
    return { oscillator: { start, stop }, output: mainGain };
  },
};
