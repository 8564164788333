export const COLORS = {
  base03: "#002b36",
  base02: "#073642",
  base01: "#586e75",
  base00: "#657b83",
  base0: "#839496",
  base1: "#93a1a1",
  base2: "#eee8d5",
  base3: "#fdf6e3",
  yellow: "#F5E900",
  orange: "#cb4b16",
  red: "#dc322f",
  kickDrum: "#FF6B6B",
  magenta: "#d33682",
  violet: "#6c71c4",
  blue: "#268bd2",
  black: "#000",
  green: "#11DF02",
  black_highlight: "#380000",
  rhodes: "#4A90E2",
  fmBrass: "#1DA5F0", // Orange color for brass
};

export const GRID_SIZE = 8;
export const NOTE_COLORS = [
  COLORS.yellow,
  COLORS.orange,
  COLORS.red,
  COLORS.magenta,
  COLORS.violet,
  COLORS.kickDrum,
  COLORS.blue,
  COLORS.rhodes,
  COLORS.green,
];
export const WAVE_TYPES = [
  "sine",
  "kickDrum",
  "square",
  "sawtooth",
  "triangle",
  "pulse",
  "rhodes",
  "noise",
  "fmBrass",
];

export const ICONS = {
  sine: "M0 10 Q5 0, 10 10 Q15 20, 20 10",
  square: "M0 0 L0 20 L10 20 L10 0 L20 0 L20 20",
  sawtooth: "M0 20 L10 0 L10 20 L20 0",
  triangle: "M0 20 L10 0 L20 20",
  pulse: "M0 0 L0 20 L8 20 L8 0 L12 0 L12 20 L20 20 L20 0",
  noise: "M0 10 L4 18 L8 2 L12 16 L16 6 L20 12",
  kickDrum: "M0 10 C5 0, 15 0, 20 10 L20 20 L0 20 Z",
  rhodes: "M10 0 L20 10 L10 20 L0 10 Z",
  fmBrass: "M0 10 C5 5, 15 5, 20 10 C15 15, 5 15, 0 10",
};

export const EFFECT_ICONS = {
  lowpass: "M0 10 C5 10, 5 0, 10 0 L20 0",
  highpass: "M0 0 L10 0 C15 0, 15 10, 20 10",
  phaser: "M0 10 C5 0, 15 20, 20 10",
  reverb: "M0 10 L5 10 L7 5 L9 15 L11 7 L13 13 L15 10 L20 10",
  delay: "M0 10 L5 10 L5 5 L10 5 L10 10 L15 10 L15 15 L20 15",
  bitcrusher: "M0 10 L4 10 L4 5 L8 5 L8 15 L12 15 L12 7 L16 7 L16 12 L20 12",
  flanger: "M0 10 C3 5, 7 15, 10 10 C13 5, 17 15, 20 10",
  reverse: "M20 0 L15 10 L20 20 M10 0 L5 10 L10 20 M0 0 L0 20",
};
